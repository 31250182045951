import React, { useEffect, useState } from "react";
import "./header.scss";
import "./headerdark.scss";
import {
  Bedtime,
  Logout,
  Menu,
  Notifications,
  VerticalAlignBottom,
} from "@mui/icons-material/";
import LightModeIcon from "@mui/icons-material/LightMode";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  Badge,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import PasswordResetModal from "../PasswordResetModal";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Sidebar from "../profileSidebar/Sidebar";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TbBrandBandcamp, TbBrandNeteaseMusic } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { ThemeMode, setTheme } from "../../redux/features/themeSwitcher";
import NotificationModal from "../modal/NotificationModal";
import { ApiConfig } from "../../services/ApiConfig";
import customAxios from "../../utils/customAxios";
// import NotificationModal from "../NotificationModal";

const options = [
  { title: "Dashboard", link: "/dashboard" },
  { title: "Discovery & Coaching", link: "/discovery-coaches" },
  { title: "Core Business Document", link: "/core-business-document" },
  // { title: "Monitor & Measure", link: "/monitor-measure" },

  { title: "Business Funding Finder", link: "/findingFunders" },
  { title: "About Us", link: "/aboutUs" },
  { title: "Cohort", link: "/my-cohort" },
];

const guestUserOptions = [
  { title: "About Us", link: "/aboutus" },
  //{ title: "Partners & Projects", link: "/partners" },
  { title: "Cohort", link: "/cohort" },
  // { title: "Community Chat", link: "/guest-community/:id" },
  { title: "Accelerator", link: "/accelarator" },
  { title: "Contact Us", link: "/contactus" },
];
const cohortOption = { title: "Cohort", link: "/my-cohort" };

// const drawerWidth = 240;

// const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create("margin", {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   })
// );

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   transition: theme.transitions.create(["margin", "width"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     width: `calc(100% - ${drawerWidth}px)`,
//     marginLeft: `${drawerWidth}px`,
//     transition: theme.transitions.create(["margin", "width"], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

function Header() {
  const token = sessionStorage.getItem("userToken");
  const location = useLocation();
  const navigate = useNavigate();
  const path = location?.pathname?.split("/");
  const matches = useMediaQuery("(min-width:1220px)");
  const theme = useTheme();
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const dispatch = useDispatch();
  const cohort = sessionStorage.getItem("cohort");
  console.log(cohort, "fdfdffd");
  useEffect(() => {
    console.log(currentTheme, "alnksfkaugfu");
  }, [currentTheme]);

  const themeChanger = () => {
    if (currentTheme == ThemeMode.LIGHT) {
      dispatch(setTheme({ mode: ThemeMode.DARK }));
    } else {
      dispatch(setTheme({ mode: ThemeMode.LIGHT }));
    }
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [flag, setFlag] = useState(false);

  const handlePasswordResetSuccess = () => {
    // Handle successful password reset logic
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    // You can perform additional actions on modal close if needed
  };

  const [open, setOpen] = React.useState(false);

  const [dots, setDots] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDotsOpen = () => {
    setDots(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDotsClose = () => {
    setDots(false);
  };

  const getAllNotification = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.findAllNotification,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res.data.statusCode === 200) {
        // setIsDataLoading(false);
        setNotificationList(res?.data?.result || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllNotification();
  }, [flag]);

  return (
    <>
      <div
        style={{
          backgroundColor: currentTheme === "DARK" ? "#151515" : "#fff",
        }}
        className={
          currentTheme === "DARK" ? "headerContainerDark" : "headerContainer"
        }
      >
        <div className="logoWrapper">
          {!matches &&
            sessionStorage?.getItem("userToken") &&
            cohort == "true" && (
              <>
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    mr: 2,
                    color: "#000",
                    ...(open && { display: "none" }),
                  }}
                >
                  <Menu
                    style={{ color: currentTheme === "DARK" ? "#fff" : "#000" }}
                    fontSize="1.2em"
                  />
                </IconButton>

                <Drawer
                  sx={{
                    // width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                      width: "240px",
                      boxSizing: "border-box",
                    },
                  }}
                  variant="persistent"
                  anchor="left"
                  open={open}
                >
                  {/* <DrawerHeader> */}
                  <IconButton
                    style={{ marginLeft: "190px" }}
                    onClick={handleDrawerClose}
                  >
                    {theme.direction === "ltr" ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                  {/* </DrawerHeader> */}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // gap: "20px",
                      fontSize: "15px",
                      alignItems: "left",
                      textDecoration: "none",
                    }}
                  >
                    {options.map((option, i) => {
                      return (
                        <Link
                          to={option.link}
                          onClick={handleDrawerClose}
                          style={{
                            color:
                              `/${path[1]}` === option?.link ? "#fff" : "#000",
                            textDecoration: "none",
                            backgroundColor:
                              `/${path[1]}` === option?.link
                                ? "#d1430c"
                                : "#fff",
                            padding: "20px",
                            "&:hover": { backgroundColor: "" },

                            // borderBottom: "1px solid black", // Uncomment or modify this line as needed
                          }}
                        >
                          {option.title}
                        </Link>
                      );
                    })}
                  </div>
                </Drawer>
              </>
            )}
          {!matches && !sessionStorage?.getItem("userToken") && (
            <>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, color: "#000", ...(open && { display: "none" }) }}
              >
                <Menu fontSize="1.2em" />
              </IconButton>

              <Drawer
                sx={{
                  // width: drawerWidth,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: "240px",
                    boxSizing: "border-box",
                  },
                }}
                variant="persistent"
                anchor="left"
                open={open}
              >
                {/* <DrawerHeader> */}
                <IconButton
                  style={{ marginLeft: "190px" }}
                  onClick={handleDrawerClose}
                >
                  {theme.direction === "ltr" ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
                {/* </DrawerHeader> */}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // gap: "20px",
                    fontSize: "15px",
                    alignItems: "left",
                    textDecoration: "none",
                  }}
                >
                  {guestUserOptions.map((option, i) => {
                    return (
                      <Link
                        to={option.link}
                        onClick={handleDrawerClose}
                        style={{
                          color:
                            `/${path[1]}` === option?.link ? "#fff" : "#000",
                          textDecoration: "none",
                          backgroundColor:
                            `/${path[1]}` === option?.link ? "#d1430c" : "#fff",
                          padding: "20px",
                          "&:hover": { backgroundColor: "" },

                          // borderBottom: "1px solid black", // Uncomment or modify this line as needed
                        }}
                      >
                        {option.title}
                      </Link>
                    );
                  })}
                </div>
              </Drawer>
            </>
          )}
          {sessionStorage.getItem("userToken") ? (
            sessionStorage.getItem("cohort") === "true" ||
            sessionStorage.getItem("cohort") === true ? (
              <Link to="/dashboard">
                <img
                  alt="logo"
                  src={
                    currentTheme === "DARK"
                      ? "/images/boostlogo2.png"
                      : "/images/boostlogo2.png"
                  }
                  height={60}
                  width={"100%"}
                />
              </Link>
            ) : (
              <div>
                <img
                  src="/images/boostlogo2.png"
                  height={60}
                  width={"100%"}
                  alt="logo"
                  style={{ pointerEvents: "none" }}
                />
              </div>
            )
          ) : (
            <Link to="/">
              <img
                alt="logo"
                src={
                  currentTheme === "DARK"
                    ? "/images/boostlogo2.png"
                    : "/images/boostlogo2.png"
                }
                height={60}
                width={"82"}                              //Home page logo
              />
            </Link>
          )}
        </div>
        <div className="headerInner">
          <div className="optionWrapper">
            {!matches && cohort === "false" && (
              <a style={{ color: "#C7340D" }} href={cohortOption?.link}>
                {cohortOption?.title}
              </a>
            )}
          </div>
          {matches && sessionStorage?.getItem("userToken") && (
            <div className="optionWrapper">
              {cohort === "false" ? (
                <a style={{ color: "#C7340D" }} href={cohortOption?.link}>
                  {cohortOption?.title}
                </a>
              ) : (
                options?.map((option, i) => (
                  <a
                    key={i}
                    href={option.link}
                    style={{
                      color:
                        currentTheme === "DARK"
                          ? `/${path[1]}` === option?.link
                            ? "#C7340D"
                            : "#fff"
                          : `/${path[1]}` === option?.link
                          ? "#C7340D"
                          : "#000",
                    }}
                  >
                    {option.title}
                  </a>
                ))
              )}
            </div>
          )}
          {matches && !sessionStorage?.getItem("userToken") && (
            <div className="optionWrapper">
              {guestUserOptions.map((option, i) => {
                return (
                  <Link
                    to={option.link}
                    style={{
                      color:
                        currentTheme === "DARK"
                          ? `/${path[1]}` === option?.link
                            ? "#C7340D"
                            : "#fff"
                          : `/${path[1]}` === option?.link
                          ? "#C7340D"
                          : "#000",
                    }}
                  >
                    {option.title}
                  </Link>
                );
              })}
            </div>
          )}
          {token ? (
            <Tooltip title="View your profile">
              <button
                onClick={() => {
                  navigate("/my-profile");
                }}
                className="core-button"
              >
                Core Profile
              </button>
            </Tooltip>
          ) : (
            <div className="newUserBtn">
              <Link to="/signup" style={{ textDecoration: "none" }}>
                {" "}
                <Box
                  mx={2}
                  sx={{
                    textDecoration: "none",
                    color: currentTheme === "DARK" ? "white" : "black",
                    fontSize: "16px",
                    fontWeight: "500",
                    "@media screen and (max-width: 400px)": {
                      fontSize: "12px",
                    },
                  }}
                  component="span"
                >
                  Sign Up
                </Box>
              </Link>
              <Link to="/login">
                <Button
                  variant="outlined"
                  style={{
                    border: "2px solid var(--Linear, #E46703)",
                    textTransform: "none",
                    fontSize: matches ? "16px" : "13px",
                    background:
                      "var(--Linear, linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%))",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    padding: !matches ? "2px 6px" : "5px 25px",
                    borderRadius: "5px",
                  }}
                >
                  Login
                  <Box
                    ml={2}
                    mt={0.4}
                    width={matches ? "19px" : "17px"}
                    component="img"
                    src="/images/Layer2.png"
                  ></Box>
                </Button>
              </Link>
            </div>
          )}
          {currentTheme === "LIGHT" ? (
            <div className="icon2">
              <Tooltip title="Dark mode">
                <Bedtime
                  style={{ fontSize: "1.rem", cursor: "pointer" }}
                  onClick={() => {
                    themeChanger();
                  }}
                />
              </Tooltip>
            </div>
          ) : (
            <div className="icon2">
              <Tooltip title="Light mode">
                <LightModeIcon
                  style={{ fontSize: "1.4em", cursor: "pointer" }}
                  onClick={() => {
                    themeChanger();
                  }}
                />
              </Tooltip>
            </div>
          )}

          {token && (
            <div style={{ display: "flex" }} className="icon">
              <div>
                {" "}
                <Badge badgeContent={notificationList?.length} color="error">
                  <Tooltip title="Notifications">
                    {currentTheme === "LIGHT" ? (
                      <Notifications
                        onClick={() => {
                          setOpenNotification(!openNotification);
                        }}
                        style={{ fontSize: "1.9em", marginBotttom: "8px" }}
                      />
                    ) : (
                      <Notifications
                        onClick={() => {
                          setOpenNotification(!openNotification);
                        }}
                        style={{
                          fontSize: "1.9em",
                          color: "white",
                          marginBotttom: "8px",
                        }}
                      />
                    )}
                  </Tooltip>
                </Badge>
              </div>
              <div>
                {" "}
                <Tooltip title="Logout">
                  {currentTheme === "LIGHT" ? (
                    <Logout
                      style={{ fontSize: "1.9em", margin: "0 0 0 12px" }}
                      onClick={() => {
                        // sessionStorage.removeItem("userToken");
                        // navigate("/");
                        handlePasswordResetSuccess();
                      }}
                    />
                  ) : (
                    <Logout
                      style={{
                        fontSize: "1.9em",
                        margin: "0 0 0 12px",
                        color: "white",
                      }}
                      onClick={() => {
                        // sessionStorage.removeItem("userToken");
                        // navigate("/");
                        handlePasswordResetSuccess();
                      }}
                    />
                  )}
                </Tooltip>
              </div>
            </div>
          )}
          <Box
            sx={{
              display: "none",
              "@media screen and (max-width:899px)": {
                display: "block",
              },
            }}
          >
            {sessionStorage?.getItem("userToken") && (
              <>
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDotsOpen}
                  edge="start"
                  sx={{ padding: "2px" }}
                  // sx={{ mr: 2, color: "#000", ...(open && { display: "none" }) }}
                >
                  <MoreVertIcon
                    style={{
                      color: currentTheme === "DARK" ? "#fff" : "#a3a3a3",
                    }}
                  />
                </IconButton>

                <Drawer
                  sx={{
                    // width: "500px",
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                      width: "240px",
                      boxSizing: "border-box",
                    },
                    overflowX: "scroll",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                  variant="persistent"
                  anchor="right"
                  open={dots}
                >
                  {/* <DrawerHeader> */}
                  <IconButton
                    style={{ marginLeft: "190px" }}
                    onClick={handleDotsClose}
                  >
                    {theme.direction === "rtl" ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                  {/* </DrawerHeader> */}

                  {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // gap: "20px",
                    fontSize: "15px",
                    alignItems: "left",
                    textDecoration: "none",
                  }}
                > */}
                  <Sidebar display={{ md: "flex", xs: "none" }} />
                  {/* </div> */}
                </Drawer>
              </>
            )}
          </Box>
        </div>

        <PasswordResetModal
          type="logout"
          open={isModalOpen}
          onClose={handleCloseModal}
        />
      </div>
      <NotificationModal
        open={openNotification}
        setOpen={setOpenNotification}
        setFlag={setFlag}
        flag={flag}
      />
    </>
  );
}

export default Header;
